
import { mapActions, mapMutations } from "vuex";
import menuItems from '~/assets/data/headerLinks.json'

export default {
  name: "LeftMenuModal",
  props: ['display'],
  components: {
    ReseauxSociaux: () => import("@/components/navigation/Header/ReseauxSociaux"),
    SubMenuList: () => import("@/components/navigation/Header/submenu/SubMenuList"),
    SubMenuButtons: () => import("@/components/navigation/Header/submenu/SubMenuButtons"),
    SubMenuFolderList: () => import("@/components/navigation/Header/submenu/SubMenuFolderList"),
  },
  data() {
    return {
      menuItems: menuItems
    };
  },
  created() {
    this.setClassName("is-active modal-left-menu");
  },
  methods: {
    ...mapMutations({
      hideModal: "modal/hideModal",
      showModal: "modal/showModal",
      setClassName: "modal/setClassName",
    }),
    ...mapActions({
      handleLeftMenu: 'header/handleLeftMenu'
    }),
  }
}
