
import { mapMutations, mapActions, mapState } from 'vuex';
import LeftMenu from "@/components/navigation/Header/LeftMenu";

  export default {
    name: "Header",
    components: {
      LeftMenu,
      TopHeader : () => import("@/components/navigation/Header/TopHeader"),
      MainMenu : () => import("@/components/navigation/Header/MainMenu"),
      SubMenu : () => import("@/components/navigation/Header/submenu/SubMenu"),
      SubMenuSearch : () => import("@/components/navigation/Header/submenu/SearchBar"),
      DonationButton : () => import("@/components/navigation/Header/DonationButton"),
    },
    async fetch(){
      try {
        this.getFoldersAndEvent()
      } catch(e) {     
        console.log(e)  
      }
    },
    mounted() {
      this.$nextTick(() => {
        // hide header on scroll
        let lastScrollY = 0;

        window.addEventListener('scroll', () => {
          const scrollY = window.scrollY;
          if (scrollY > lastScrollY) {
            this.activateScroll()
          } else {
            this.desactivateScroll()
          }
          lastScrollY = scrollY;

          // scale the header's height according to position on the page
          scrollY < 150 ? this.setSlimHeader(false) : this.setSlimHeader(true)
        });
      });
    },
    methods : {
      ...mapActions({
        getFoldersAndEvent: 'header/getFoldersAndEvent'
      }),
      ...mapMutations({
        closeSubMenu: 'header/closeSubMenu',
        closeSearchBar: 'header/closeSearchBar',
        activateScroll: 'header/activateScroll',
        desactivateScroll: 'header/desactivateScroll',
        setSlimHeader: 'header/setSlimHeader',
      }),
      closeMenus() {
        this.closeSubMenu()
        this.closeSearchBar()
      }
    },
    computed: {
      ...mapState('header', {
        scroll: state => state.scroll,
        subMenu: state => state.displaySubMenu,
        searchBar: state => state.displaySearchBar,
        leftMenu: state => state.displayLeftMenu
      })
    }
  }

